import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://alsofi.alsharif.travel/ar',
  
  timeout: 10000,
    headers: {
    'Content-Type': 'application/json',
  },
  // لن تحتاج https.Agent
  validateStatus: () => true, // قبول جميع الحالات
});

export default axiosInstance;


